<template>
<div class="kt-container kt-grid__item kt-grid__item--fluid">
    <div class="row">
        <div class="col-12">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Anecdotal Categories
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-actions">
                            <a
                                href="#"
                                class="btn btn-outline-success btn-bold btn-sm"
                                @click.stop.prevent="createNewCategory()"
                            > Add Category </a>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <div class="table-responsive pb-3">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <td>Category Name</td>
                                    <td>Worth</td>
                                    <td>Subscribers</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="category in categories"
                                    :key="`anec_${category.anecdotalEventCategoryId}`"
                                >
                                    <td class="row_desc ">
                                        <div class="media">
                                            <span
                                                v-if="category.categoryIcon"
                                                style="font-size: 2rem;"
                                                class="mr-3"
                                            >
                                                {{ category.categoryIcon }}
                                            </span>
                                            <div class="media-body align-self-center">
                                                <router-link
                                                    :to="{
                                                        name: 'EditAnecdotalCategory',
                                                        params: {
                                                            anecdotalEventCategoryId: category.anecdotalEventCategoryId
                                                        },
                                                    }"
                                                    :class="`anchor-link font ${category.categoryColor}`"
                                                >
                                                    {{ category.anecdotalEventCategoryTitle }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="row_worth text-center">
                                        <div>
                                            <span
                                                :title="formatNumber(category.pointValue)"
                                                class="kt-point-badge pull-right"
                                                :class="{
                                                    'kt-font-success': category.pointValue > 0,
                                                    'kt-font-danger': category.pointValue < 0,
                                                }"
                                            >
                                                {{ category.pointValue > 0 ? '+' : '' }}
                                                {{ abbrevNumber(category.pointValue) }}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="row_subs ">
                                        <AnecdotalSubscribers :category="category" />
                                    </td>
                                    <td class="row_delete text-center">
                                        <button
                                            :v-b-tooltip.hover="'Delete Category'"
                                            type="button"
                                            class="btn btn-clean btn-clean-danger btn-icon btn-sm"
                                            @click.stop.prevent="deleteCategory(category)"
                                        >
                                            <i class="la la-trash" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Types from '../../store/Types';
import * as network from '../../network';
import AnecdotalSubscribers from './AnecdotalSubscribers.vue';

export default {
    name: 'AnecdotalSetup',
    components: {
        AnecdotalSubscribers,
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
        }),
        categories() {
            const sorter = function (a, b) {
                return a.anecdotalEventCategoryTitle > b.anecdotalEventCategoryTitle ? 1 : b.anecdotalEventCategoryTitle > a.anecdotalEventCategoryTitle ? -1 : 0;
            };
            return this.anecdotalEventCategories.slice(0).sort(sorter);
        },
    },
    mounted() {
    },
    methods: {
        createNewCategory() {
            // this.$refs['add-edit-anecdotal-category-modal'].open();
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: 'new' } });
        },
        editCategory(category) {
            // this.$refs['add-edit-anecdotal-category-modal'].open(category);
            this.$router.push({ name: 'EditAnecdotalCategory', params: { anecdotalEventCategoryId: category.anecdotalEventCategoryId } });
        },
        deleteCategory(anecdotalEventCategory) {
            const v = this;
            const { schoolId, schoolTermId } = v.user.school;
            const { anecdotalEventCategoryId } = anecdotalEventCategory;
            network.anecdotals.deleteCategory({ url: { schoolId, schoolTermId, anecdotalEventCategoryId } }, (err) => {
                if (err) return v.showError(err);
                v.$store.commit(Types.mutations.DELETE_ANECDOTAL_EVENT_CATEGORY, { anecdotalEventCategoryId });
            });
        },
    },
};
</script>

<style scoped src='../../css/colors.scss' lang="scss" />

<style scoped>
span.kt-point-badge {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: -12px;
    white-space: nowrap;
}
.table tr td {
    vertical-align: middle;
}
td.row_actions {
    min-width: 100px;
    white-space: nowrap;
}
td.row_desc {
    min-width: 200px;
}
td.row_subs {
    min-width: 200px;
}

.table thead tr td {
    padding-bottom: 20px;
}

a.anchor-link:hover {
    text-decoration: underline !important;
    text-underline-offset: 4px;
}
</style>
