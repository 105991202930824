var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shownUsers.length > 0
    ? _c(
        "div",
        { staticClass: "kt-media-group" },
        [
          _vm._l(_vm.shownUsers, function (user, idx) {
            return _c(
              "router-link",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.blur.left",
                    modifiers: { hover: true, blur: true, left: true },
                  },
                ],
                key: `linked_media-group_${idx}_${user.studentEnrollmentId}_${user.schoolStaffId}`,
                staticClass: "kt-media kt-media--sm kt-media--info",
                attrs: {
                  title: `${user.lastName}, ${user.firstName}`,
                  to: {
                    name: _vm.$route.name, // same page
                    params: _vm.$route.params, // self params
                    query: {
                      ..._vm.$route.query,
                    },
                  },
                },
              },
              [
                user.pictureGuid || user.awsImageUrl
                  ? _c(
                      "UserAvatar",
                      {
                        staticStyle: { width: "50px" },
                        attrs: { "avatar-user": user },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(user.lastName.substring(0, 1)) +
                              _vm._s(user.firstName.substring(0, 1)) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(user.lastName.substring(0, 1)) +
                          _vm._s(user.firstName.substring(0, 1)) +
                          " "
                      ),
                    ]),
              ],
              1
            )
          }),
          _vm.maxSubscribers < _vm.totalSubcribers
            ? _c(
                "a",
                {
                  staticClass: "kt-media kt-media--circle",
                  staticStyle: {
                    "background-color": "rgba(15, 136, 239, 0.7)",
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { color: "white" } }, [
                    _vm._v(
                      " +" +
                        _vm._s(_vm.totalSubcribers - _vm.maxSubscribers) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      )
    : _c("div", [
        _c("p", { staticClass: "mb-0" }, [_vm._v(" No Subscribers ")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }