var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-container kt-grid__item kt-grid__item--fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _vm._m(0),
              _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _c("div", { staticClass: "kt-portlet__head-actions" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-success btn-bold btn-sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.createNewCategory()
                        },
                      },
                    },
                    [_vm._v(" Add Category ")]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "table-responsive pb-3" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(1),
                  _c(
                    "tbody",
                    _vm._l(_vm.categories, function (category) {
                      return _c(
                        "tr",
                        { key: `anec_${category.anecdotalEventCategoryId}` },
                        [
                          _c("td", { staticClass: "row_desc" }, [
                            _c("div", { staticClass: "media" }, [
                              category.categoryIcon
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mr-3",
                                      staticStyle: { "font-size": "2rem" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(category.categoryIcon) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "media-body align-self-center" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      class: `anchor-link font ${category.categoryColor}`,
                                      attrs: {
                                        to: {
                                          name: "EditAnecdotalCategory",
                                          params: {
                                            anecdotalEventCategoryId:
                                              category.anecdotalEventCategoryId,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            category.anecdotalEventCategoryTitle
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("td", { staticClass: "row_worth text-center" }, [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "kt-point-badge pull-right",
                                  class: {
                                    "kt-font-success": category.pointValue > 0,
                                    "kt-font-danger": category.pointValue < 0,
                                  },
                                  attrs: {
                                    title: _vm.formatNumber(
                                      category.pointValue
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        category.pointValue > 0 ? "+" : ""
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.abbrevNumber(category.pointValue)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "td",
                            { staticClass: "row_subs" },
                            [
                              _c("AnecdotalSubscribers", {
                                attrs: { category: category },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "row_delete text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-clean btn-clean-danger btn-icon btn-sm",
                                attrs: {
                                  "v-b-tooltip": "Delete Category",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.deleteCategory(category)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "la la-trash" })]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Anecdotal Categories "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Category Name")]),
        _c("td", [_vm._v("Worth")]),
        _c("td", [_vm._v("Subscribers")]),
        _c("td", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }