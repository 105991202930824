<template>
<div
    v-if="shownUsers.length > 0"
    class="kt-media-group"
>
    <router-link
        v-for="(user, idx) in shownUsers"
        :key="`linked_media-group_${idx}_${user.studentEnrollmentId}_${user.schoolStaffId}`"
        v-b-tooltip.hover.blur.left
        :title="`${user.lastName}, ${user.firstName}`"
        class="kt-media kt-media--sm kt-media--info"
        :to="{
            name: $route.name, // same page
            params: $route.params, // self params
            query: {
                ...$route.query
            },
        }"
    >
        <UserAvatar
            v-if="user.pictureGuid || user.awsImageUrl"
            style="width: 50px"
            :avatar-user="user"
        >
            <span>
                {{ user.lastName.substring(0, 1) }}{{ user.firstName.substring(0, 1) }}
            </span>
        </UserAvatar>
        <span v-else>
            {{ user.lastName.substring(0, 1) }}{{ user.firstName.substring(0, 1) }}
        </span>
    </router-link>

    <a
        v-if="maxSubscribers < totalSubcribers"
        href="#"
        class="kt-media kt-media--circle"
        style="background-color: rgba(15, 136, 239, 0.7);"
        @click.stop.prevent=""
    >
        <span style="color: white;">
            +{{ totalSubcribers - maxSubscribers }}
        </span>
    </a>
</div>
<div v-else>
    <p class="mb-0">
        No Subscribers
    </p>
</div>
</template>

<script>

import { mapState } from 'vuex';
import UserAvatar from '../../components/UserAvatar.vue';
import panelMixins from '../../store/mixins/panelMixins';

export default {
    name: 'AnecdotalSubscribers',
    components: {
        UserAvatar,
    },
    mixins: [panelMixins],
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            maxSubscribers: 3,
        };
    },
    computed: {
        ...mapState({
            teachers: (state) => state.database.teachers,
            externalDistrictId: (state) => state.user.school.externalDistrictId,
        }),
        subscribers() {
            return this.category?.subscribers || [];
        },
        totalSubcribers() {
            return this.subscribers.length;
        },
        shownUsers() {
            return this.subscribed.slice(0, this.maxSubscribers);
        },
        subscribed() {
            const v = this;
            return this.subscribers.map((s) => v.teachers
                .find((t) => t.userId == s.userId) || null)
                .filter((x) => x);
        },
    },
    methods: {
        openPanelForStudent(student) {
            this.$_panelMixins_openPanelForStudent(student);
        },
    },
};
</script>

<style scoped>

div.kt-media-group {

}
div.student-pic {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.kt-widget--user-profile-1 span {
  line-height: 0;
}

.kt-widget__username {
  font-size: 14px !important;
}

.kt-widget__media {
  width: 25%;
  max-width: 25%;
}
</style>
